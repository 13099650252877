import { NgModule } from "@angular/core";
import { StoreModule } from '@ngrx/store';
import { UserManagementStateReducer } from "./user-management.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('user-management', UserManagementStateReducer.reducer)
    ]
})
export class UserManagementStateModule {}
