import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ViewTypeEnum } from '../../enum/view-type.enum';

@Component({
	selector: 'app-viewtype-toggle',
	templateUrl: './viewtype-toggle.component.html',
	styleUrls: ['./viewtype-toggle.component.scss']
})
export class ViewtypeToggleComponent implements OnInit {
	@Input() viewTypeDefault = ViewTypeEnum.list;
	@Output() changingViewList: EventEmitter<ViewTypeEnum> = new EventEmitter();

	public viewTypeEnum = ViewTypeEnum;

	constructor() {}

	ngOnInit() {
		this.changingViewList.emit(this.viewTypeDefault);
	}

	setViewType(viewType: ViewTypeEnum) {
		this.changingViewList.emit(viewType);
	}
}
