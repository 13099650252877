export enum Singolar {
	SC = 'Sacco',
	M3 = 'm<sup>3</sup>',
	KG = 'Kg',
	TN = 'Tonnellata',
	MQ = 'm<sup>2</sup>',
	NR = 'Confezione',
	CF = 'Confezione',
	ML = 'Metro Lineare',
	PZ = 'Pezzo'
}
export enum Plural {
	SC = 'Sacchi',
	TN = 'Tonnellate',
	NR = 'Confezioni',
	CF = 'Confezioni',
	PZ = 'Pezzi'
}
export class MeasureFormatter {
	constructor() {}

	public static getMeasure(key: string, counter?: number, bancali?: boolean, packaging?: boolean) {
		let measureLabel: string;
		if (packaging && bancali) {
			measureLabel = counter > 1 ? 'Bancali' : 'Bancale';
		} else {
			if (counter && counter > 1) {
				measureLabel = Plural[key] ? Plural[key] : Singolar[key];
			} else {
				measureLabel = Singolar[key];
			}
		}
		return measureLabel;
	}
}
