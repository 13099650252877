import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

export interface EventObserver {
  [sourceName: string]: Subscription;
}

export interface EventObservableModel {
  source: Subject<any>;
  observer: EventObserver;
}

export interface EventModel {
  [key: string]: EventObservableModel;
}

@Injectable()
export class EventHandlerService {

  private _eventsSource: EventModel = {};

  get eventsSource() {
    return {...this._eventsSource};
  }

  constructor() {}

  addEvent(evtName: string, source: Subject<any>) {
    if (!this.getSource(evtName)) {
      const evtObj = {
        source,
        observer: {}
      };
      this._eventsSource[evtName] = evtObj;
    }
  }

  emitEvent(evtName: string, data: any): void {
    if (this.getSource(evtName)) {
      this.getSource(evtName).next(data);
    }
  }

  getSourceObservable(evtName: string): Observable<any> {
    const evtSource = this.getSource(evtName);
    if (evtSource) {
      return evtSource.asObservable();
    }
  }

  getSource(evtName: string): Subject<any> {
    if (this._eventsSource[evtName] && this._eventsSource[evtName].source){
      return this._eventsSource[evtName].source;
    }
    return null;
  }

  getSourceObject(evtName: string): EventObservableModel {
    if (this._eventsSource[evtName]) {
      return this._eventsSource[evtName];
    }
    return null;

  }

  subscribeToEvent(evtName: string, next: Function, sourceName: string): void {
    const sourceObj = this.getSourceObject(evtName);
    if (sourceObj) {
      if (this.isSubscribed(evtName, sourceName)){
        this.unsubscribe(evtName, sourceName);
      }
      const subscription = sourceObj.source.subscribe(res => {
        next(res);
      });
      sourceObj.observer[sourceName] = subscription;
    }
  }

  hasEvent(evtName: string): boolean {
    return !!this.getSource(evtName);
  }

  isSubscribed(evtName: string, sourceName: string): boolean {
    const sourceObj =  this.getSourceObject(evtName);
    if (sourceObj) {
      return !!sourceObj.observer[sourceName];
    }
    return false;
  }

  unsubscribe(evtName: string, sourceName: string): boolean {
    const sourceObj = this.getSourceObject(evtName);
    if (sourceObj && this.isSubscribed(evtName, sourceName)) {
      sourceObj.observer[sourceName].unsubscribe();
      return true;
    }
    return false;
  }

  private resetHandler() {
    this._eventsSource = {};
  }
}
