import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { UserActivateStateReducer } from "./user-activate.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('user-activate', UserActivateStateReducer.reducer)
    ]
})
export class UserActivateStateModule{}
