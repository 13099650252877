import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';

import { ConnectionModel } from '../../model/connection.model';
import { ConnectionActionEnum, ConnectionStateAction } from './connection.actions';

export namespace ConnectionStateReducer {
	export const initialState: BaseStateModel<ConnectionModel> = new BaseState({ offline: false });

	const _reducer = createReducer(
		initialState,
		on(ConnectionStateAction.load, state => state),
		on(ConnectionStateAction.update, (state, connection) => connection),
		on(ConnectionStateAction.error, (state, connection) => ({
			data: state ? state.data : null,
			type: connection.type,
			status: connection.status
		}))
	);

	export function reducer(state: BaseStateModel<ConnectionModel>, action: TypedAction<ConnectionActionEnum>) {
		return _reducer(state, action);
	}
}
