import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsDetailBalance } from '../../model/statistics-detail-balance.model';

export enum StatisticsDetailExpiredActionEnum {
	LOAD = '[statistics-detail-expired] Load',
	UPDATE = '[statistics-detail-expired] Update',
	RESET = '[statistics-detail-expired] Reset',
	ERROR = '[statistics-detail-expired] Error'
}

export namespace StatisticsDetailExpiredStateAction {
	export const load = createAction(StatisticsDetailExpiredActionEnum.LOAD);
	export const update = createAction(
		StatisticsDetailExpiredActionEnum.UPDATE,
		props<BaseStateModel<StatisticsDetailBalance>>()
	);
	export const reset = createAction(StatisticsDetailExpiredActionEnum.RESET);
	export const error = createAction(StatisticsDetailExpiredActionEnum.ERROR, props<BaseStateModel<any>>());
}
