import { Component, OnInit, Input } from '@angular/core';

export enum AlertType {
	warning = 'warning',
	danger = 'danger'
}

@Component({
	selector: 'alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	@Input() type: AlertType;

	constructor() {}

	ngOnInit() {}
}
