import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'highlight-number',
	templateUrl: './highlight-number.component.html',
	styleUrls: ['./highlight-number.component.scss']
})
export class HighlightNumberComponent implements OnInit {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() value: string | number;

	constructor() {}

	ngOnInit() {}
}
