import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticsOrganizationStateAction, StatisticsOrganizationActionEnum } from './statistics-organization.action';
import { StatisticsOrganization } from '../../model/statistics-organization.model';

export namespace StatisticsOrganizationStateReducer {
	export const initialState: BaseStateModel<StatisticsOrganization> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsOrganizationStateAction.load, state => state),
		on(StatisticsOrganizationStateAction.update, (state, order) => order),
		on(StatisticsOrganizationStateAction.reset, () => initialState),
		on(StatisticsOrganizationStateAction.error, (state, order) => ({
			data: state ? state.data : null,
			type: order.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticsOrganization>,
		action: TypedAction<StatisticsOrganizationActionEnum>
	) {
		return _reducer(state, action);
	}
}
