import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerCreditPouchModelCustom } from './company-account-balance.reducer';
import { CompanyAccountBalanceFilterModel } from '../../service/pouch-db/filter/company-account-balance-filter.model';

export enum CompanyAccountBalanceActionEnum {
	// detail
	UPDATE = '[Company account balance] Update',
	LOAD = '[Company account balance] Load',
	RESET = '[Company account balance] Reset',
	SAVE = '[Company account balance] Save',
	REMOVE = '[Company account balance] Delete',
	ERROR = '[Company account balance] Error'
}

export namespace CompanyAccountBalanceAction {
	// detail
	export const update = createAction(
		CompanyAccountBalanceActionEnum.UPDATE,
		props<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>>()
	);
	export const load = createAction(
		CompanyAccountBalanceActionEnum.LOAD,
		props<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>>()
	);
	export const save = createAction(
		CompanyAccountBalanceActionEnum.SAVE,
		props<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>>()
	);
	export const remove = createAction(
		CompanyAccountBalanceActionEnum.REMOVE,
		props<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>>()
	);
	export const reset = createAction(CompanyAccountBalanceActionEnum.RESET);
}
