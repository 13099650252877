<mat-card>
	<mat-card-content>
		<div class="highlight-value__header">
			<h1>{{ title | translate | sentencecase }}</h1>
			<h2>{{ subtitle | translate | sentencecase }}</h2>
		</div>
		<div class="highlight-value__main">
			<p class="highlight-value__main__percentage">{{ percentage }} %</p>
			<p class="highlight-value__main__amount">{{ value | currency: 'EUR':'symbol':'':'it' }}</p>
		</div>
	</mat-card-content>
</mat-card>
