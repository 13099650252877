import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appPercentInput]'
})
export class PercentInputDirective {
	// Not allow ending with a .
	private onLeavePercentRegex: RegExp = /(^100(\.0{1})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1})?$)/;

	// Allow '25', '25.', '25.6' while writing
	private onKeyDownPercentRegex: RegExp = /(^100([\.\,]0{0,1})?$)|(^([1-9]([0-9])?|0)([\.\,][0-9]{0,1})?$)/;

	constructor(private _elementRef: ElementRef) {}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		const input = e.target as HTMLInputElement;
		if (
			// Allow: Delete, Backspace, Tab, Escape, Enter
			[46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
			(e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
			(e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
			(e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
			(e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
			(e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
			(e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
			(e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
			(e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
			(e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
		) {
			return; // let it happen, don't do anything
		}
		const inputValue = input.value + e.key;
		const regexMatch = this.onKeyDownPercentRegex.test(inputValue);
		if (!regexMatch) {
			e.preventDefault();
		}
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(e: KeyboardEvent) {
		const input = e.target as HTMLInputElement;
		this._elementRef.nativeElement.value = input.value.replace(',', '.');
	}

	@HostListener('focusout', ['$event'])
	onFocusOut(e: FocusEvent) {
		const input = e.target as HTMLInputElement;
		const inputValue = input.value;
		const regexMatch = this.onLeavePercentRegex.test(inputValue);
		if (!regexMatch) {
			this._elementRef.nativeElement.value = this._elementRef.nativeElement.value.slice(0, -1);
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
		document.execCommand('insertText', false, pastedInput);
	}

	@HostListener('drop', ['$event'])
	onDrop(event: any) {
		event.preventDefault();
		const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
		this._elementRef.nativeElement.focus();
		document.execCommand('insertText', false, textData);
	}
}
