import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsAgent } from '../../model/statistics-agent.model';

export enum StatisticsAgentActionEnum {
	LOAD = '[statistics-agent] Load',
	UPDATE = '[statistics-agent] Update',
	RESET = '[statistics-agent] Reset',
	ERROR = '[statistics-agent] Error'
}

export namespace StatisticsAgentStateAction {
	export const load = createAction(StatisticsAgentActionEnum.LOAD, props<BaseStateModel<any>>());
	export const update = createAction(StatisticsAgentActionEnum.UPDATE, props<BaseStateModel<StatisticsAgent>>());
	export const reset = createAction(StatisticsAgentActionEnum.RESET);
	export const error = createAction(StatisticsAgentActionEnum.ERROR, props<BaseStateModel<any>>());
}
