<div class="card-address">
	<h3>{{ destination.business_name }}</h3>
	<p>{{ destination.address.address }}</p>
	<p>{{ destination.address.zip_code }} - {{ destination.address.locality }} ({{ destination.address.province }})</p>
	<p>{{ destination.address.country }}</p>

	<button mat-icon-button aria-label="Edit address" (click)="editAddress()">
		<mat-icon>edit</mat-icon>
	</button>
</div>
