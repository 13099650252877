export interface IAnimationOptions {
	loop?: boolean;
	animationDuration?: string;
	easing?: string;
}

export interface IMediaConfig {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
}

export interface ICardSliderConfiguration<T = any[]> {
	data: T;
	medias?: IMediaConfig;
	animation?: IAnimationOptions;
}

export enum ItemType {
	category = 'category',
	product = 'product'
}
