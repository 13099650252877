import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class PageDataService<T>{
  private dataSource = new BehaviorSubject<T>(null);
  currentData$ = this.dataSource.asObservable();

  constructor() { }

  populate(message: T) {
    this.dataSource.next(message);
  }
}
