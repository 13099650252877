import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'chart-no-data',
	templateUrl: './chart-no-data.component.html',
	styleUrls: ['./chart-no-data.component.scss']
})
export class ChartNoDataComponent implements OnInit {
	@Input() icon = 'leaderboard';

	constructor() {}

	ngOnInit() {}
}
