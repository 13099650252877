<p class="opportunity-rating__title">Qualità dell’opportunità</p>
<div class="opportunity-rating">
	<div class="opportunity-rating__avg">
		<div class="badge--rating--32">
			{{ ratingAvg }}
		</div>
	</div>
	<div class="opportunity-rating__content">
		<ng-container *ngFor="let rating of ratingList">
			<div class="opportunity-rating__label">{{ rating.name }}</div>
			<div class="opportunity-rating__percentage">{{ rating.value }}%</div>
			<div class="opportunity-rating__bar">
				<div class="bar" [style.width]="rating.value + '%'"></div>
			</div>
		</ng-container>
	</div>
</div>
