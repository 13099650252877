import { createReducer, on } from '@ngrx/store';
import { OrganizationCreditPouchModel, CustomerCreditSummaryTablePouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CompanyAccountBalanceAction } from './company-account-balance.actions';
import { CompanyAccountBalanceFilterModel } from '../../service/pouch-db/filter/company-account-balance-filter.model';

export interface CustomerCreditPouchModelCustom {
	header: OrganizationCreditPouchModel;
	summary: CustomerCreditSummaryTablePouchModel;
}

export namespace CompanyAccountBalanceReducer {
	export const initialState: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(CompanyAccountBalanceAction.update, (state, backendProcessQueue) =>
			JSON.parse(JSON.stringify(backendProcessQueue))
		),
		on(CompanyAccountBalanceAction.load, state => state),
		on(CompanyAccountBalanceAction.save, state => state),
		on(CompanyAccountBalanceAction.reset, state => initialState),
		on(CompanyAccountBalanceAction.remove, state => state)
	);

	export function reducer(state, action) {
		return _reducer(state, action);
	}
}
