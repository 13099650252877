import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticsDetailOrdersStateAction, StatisticsDetailOrdersActionEnum } from './statistics-detail-orders.action';
import { StatisticsDetailOrders } from '../../model/statistics-detail-orders.model';

export namespace StatisticsDetailOrdersStateReducer {
	export const initialState: BaseStateModel<StatisticsDetailOrders> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsDetailOrdersStateAction.load, state => state),
		on(StatisticsDetailOrdersStateAction.update, (state, statistics) => statistics),
		on(StatisticsDetailOrdersStateAction.reset, () => initialState),
		on(StatisticsDetailOrdersStateAction.error, (state, statistics) => ({
			data: state ? state.data : null,
			type: statistics.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticsDetailOrders>,
		action: TypedAction<StatisticsDetailOrdersActionEnum>
	) {
		return _reducer(state, action);
	}
}
