import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsDetailClients } from '../../model/statistics-detail-clients.model';

export enum StatisticsDetailClientsActionEnum {
	LOAD = '[statistics-detail-clients] Load',
	UPDATE = '[statistics-detail-clients] Update',
	RESET = '[statistics-detail-clients] Reset',
	ERROR = '[statistics-detail-clients] Error'
}

export namespace StatisticsDetailClientsStateAction {
	export const load = createAction(StatisticsDetailClientsActionEnum.LOAD);
	export const update = createAction(
		StatisticsDetailClientsActionEnum.UPDATE,
		props<BaseStateModel<StatisticsDetailClients>>()
	);
	export const reset = createAction(StatisticsDetailClientsActionEnum.RESET);
	export const error = createAction(StatisticsDetailClientsActionEnum.ERROR, props<BaseStateModel<any>>());
}
