import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsDetailSold } from '../../model/statistics-detail-sold.model';

export enum StatisticsDetailSoldActionEnum {
	LOAD = '[statistics-detail-sold] Load',
	UPDATE = '[statistics-detail-sold] Update',
	RESET = '[statistics-detail-sold] Reset',
	ERROR = '[statistics-detail-sold] Error'
}

export namespace StatisticsDetailSoldStateAction {
	export const load = createAction(StatisticsDetailSoldActionEnum.LOAD);
	export const update = createAction(
		StatisticsDetailSoldActionEnum.UPDATE,
		props<BaseStateModel<StatisticsDetailSold>>()
	);
	export const reset = createAction(StatisticsDetailSoldActionEnum.RESET);
	export const error = createAction(StatisticsDetailSoldActionEnum.ERROR, props<BaseStateModel<any>>());
}
