import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { AgentListStateReducer } from "./agent-list.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('agent-list', AgentListStateReducer.reducer)
    ]
})
export class AgentListStateModule{}
