import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import {
	StatisticsDetailClientsStateAction,
	StatisticsDetailClientsActionEnum
} from './statistics-detail-clients.action';
import { StatisticsDetailClients } from '../../model/statistics-detail-clients.model';

export namespace StatisticsDetailClientsStateReducer {
	export const initialState: BaseStateModel<StatisticsDetailClients> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsDetailClientsStateAction.load, state => state),
		on(StatisticsDetailClientsStateAction.update, (state, statistics) => statistics),
		on(StatisticsDetailClientsStateAction.reset, () => initialState),
		on(StatisticsDetailClientsStateAction.error, (state, statistics) => ({
			data: state ? state.data : null,
			type: statistics.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticsDetailClients>,
		action: TypedAction<StatisticsDetailClientsActionEnum>
	) {
		return _reducer(state, action);
	}
}
