<ng-container *ngIf="destinationList && destinationList.length; else noDataFound">
	<div class="address__content">
		<!-- grid -->
		<ng-container *ngIf="viewType === viewTypeEnum.module">
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-4 mb-3" *ngFor="let destination of destinationList; index as i">
					<app-card-address
						[destination]="destination"
						(editingAddress)="editAddress($event)"
					></app-card-address>
				</div>
			</div>
		</ng-container>

		<!-- list -->
		<ng-container *ngIf="viewType === viewTypeEnum.list">
			<td-data-table
				#dataTable
				[data]="destinationList"
				[columns]="columnsToShow"
				[clickable]="mode == ListMode.pickable"
				(rowClick)="rowClick($event)"
			>
				<ng-template tdDataTableTemplate="action" let-row="row">
					<button mat-icon-button aria-label="Edit Address" (click)="editAddress(row)">
						<mat-icon>edit</mat-icon>
					</button>
				</ng-template>
			</td-data-table>
		</ng-container>
	</div>
</ng-container>

<ng-template #noDataFound>
	<app-no-data-found text="Nessun indirizzo presente"></app-no-data-found>
</ng-template>
