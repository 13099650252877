import { OrderStateCount } from './order.model';
import { ListStructureModel } from '@saep-ict/angular-core';

// lists
export interface CompanyListModel extends ListStructureModel<CompanyModel[]> {}
export interface AddressListModel extends ListStructureModel<AddressModel[]> {}
export interface PersonListModel extends ListStructureModel<PersonModel[]> {}

// FIXME PROCESSING E DRAFT DA NUMBER  STRING
// details
export interface CompanyModel {
	id?: string;
	client_code?: string;
	code?: string;
	business_name?: string;
	draft?: string;
	processing?: string;
	order_states_count?: OrderStateCount[];
	due?: number;
	over_due?: number;
	person_favorite?: PersonModel;
	persons?: PersonListModel;
	tel?: string;
	fax?: string;
	piva?: string;
	cf?: string;
	address_favorite?: AddressModel;
	addresses?: AddressListModel;
	email?: string;
	note?: string;
	state?: string;
}

export enum EnumRole {
	CEO = 'Chief Executive Officer (CEO)',
	COO = 'Chief Operating Officer (COO)',
	VICE_MARKETING_MANAGER = 'Vice President of Marketing or Marketing Manager',
	CFO = 'Chief Financial Officer (CFO) or Controller',
	PRODUCTION_MANAGER = 'Vice President of Production or Production Manager',
	OPERATIONS_MANAGER = 'Operations manager',
	ENVIRONMENTAL_MANAGER = 'Quality control, safety, environmental manager',
	CONTROLLER = 'Accountant, bookkeeper, controller',
	OFFICE_MANAGER = 'Office manager',
	RECEPTIONIST = 'Receptionist',
	SUPERVISOR = 'Foreperson, supervisor, lead person',
	MARKETING_MANAGER = 'Marketing manager',
	PURCHASING_MANAGER = 'Purchasing manager',
	SHIPPING_MAANGER = 'Shipping and receiving person or manager',
	PROFESSIONAL_STAFF = 'Professional staff'
}

export interface PersonModel {
	id?: string;
	first_name?: string;
	last_name?: string;
	role?: EnumRole;
	business_name?: string;
	email?: string;
	mobilephone?: string;
	telephone?: string;
	address?: AddressModel;
	favorite?: boolean;
	note?: string;
}

export interface AddressModel {
	id?: string;
	province?: string;
	city?: string;
	street_address?: string; // via e numero civico
	postal_code?: string;
	country?: string;
	telephone?: string;
	type?: EnumAddressType;
}

export enum EnumAddressType {
	LEGAL_ADDRESS = 'Legal address',
	OTHER_ADDRESS = 'Other address',
	DESTINATION = 'Destination'
}

export interface CompanyMostActiveModel extends CompanyModel {
	revenue: number;
	revenue_trend_percentage: number;
	contribution_on_total_percentage: number;
}
