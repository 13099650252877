import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'app-card-address',
	templateUrl: './card-address.component.html',
	styleUrls: ['./card-address.component.scss']
})
export class CardAddressComponent {
	@Input() destination: DestinationPouchModel;
	@Output() editingAddress: EventEmitter<DestinationPouchModel> = new EventEmitter();

	constructor() {}

	editAddress() {
		this.editingAddress.emit(this.destination);
	}
}
