import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'appFormatMultiplesCurrency'
})
export class FormatMultiplesCurrency implements PipeTransform {
	transform(value: number): string {
		if (value === undefined || value === null) {
			return '';
		}
		let result = '' + value;
		if (Math.abs(value) > 1000) {
			result = (value / 1000).toFixed(2) + 'k';
		} else if (value > 0) {
			result = value.toFixed(2);
		}
		return result.replace('.', ',') + ' €';
	}
}
