import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticsDetailSold } from '../../model/statistics-detail-sold.model';
import { StatisticsDetailSoldActionEnum, StatisticsDetailSoldStateAction } from './statistics-detail-sold.action';

export namespace StatisticsDetailSoldStateReducer {
	export const initialState: BaseStateModel<StatisticsDetailSold> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsDetailSoldStateAction.load, state => state),
		on(StatisticsDetailSoldStateAction.update, (state, statistics) => statistics),
		on(StatisticsDetailSoldStateAction.reset, () => initialState),
		on(StatisticsDetailSoldStateAction.error, (state, statistics) => ({
			data: state ? state.data : null,
			type: statistics.type
		}))
	);

	export function reducer(
		state: BaseStateModel<StatisticsDetailSold>,
		action: TypedAction<StatisticsDetailSoldActionEnum>
	) {
		return _reducer(state, action);
	}
}
