import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticsCrmStateAction, StatisticsCrmActionEnum } from './statistics-crm.action';
import { StatisticsCrm } from '../../model/statistics-crm.model';

export namespace StatisticsCrmStateReducer {
	export const initialState: BaseStateModel<StatisticsCrm> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsCrmStateAction.load, state => state),
		on(StatisticsCrmStateAction.update, (state, order) => order),
		on(StatisticsCrmStateAction.reset, () => initialState),
		on(StatisticsCrmStateAction.error, (state, order) => ({
			data: state ? state.data : null,
			type: order.type
		}))
	);

	export function reducer(state: BaseStateModel<StatisticsCrm>, action: TypedAction<StatisticsCrmActionEnum>) {
		return _reducer(state, action);
	}
}
