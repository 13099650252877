import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigModel } from './model/structure/environment-config.model';

@Injectable()
export class AppConfig {
	private configSource = new BehaviorSubject<ConfigModel>(<ConfigModel>{});
	config$ = this.configSource.asObservable();

	constructor() {}

	populateConfig(configuration: ConfigModel) {
		this.configSource.next(configuration);
	}
	get config(): ConfigModel {
		return this.configSource.getValue();
	}
}
