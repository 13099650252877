import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsOrders } from '../../model/statistics-orders.model';
import { StatisticsOrdersFilter } from '../../service/pouch-db/filter/statistics-orders-filter.model';

export enum StatisticsOrdersActionEnum {
	LOAD = '[statistics-orders] Load',
	UPDATE = '[statistics-orders] Update',
	RESET = '[statistics-orders] Reset',
	ERROR = '[statistics-orders] Error'
}

export namespace StatisticsOrdersStateAction {
	export const load = createAction(
		StatisticsOrdersActionEnum.LOAD,
		props<BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>>()
	);
	export const update = createAction(
		StatisticsOrdersActionEnum.UPDATE,
		props<BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>>()
	);
	export const reset = createAction(StatisticsOrdersActionEnum.RESET);
	export const error = createAction(StatisticsOrdersActionEnum.ERROR, props<BaseStateModel<any>>());
}
