import { createAction, props } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsCrm } from '../../model/statistics-crm.model';

export enum StatisticsCrmActionEnum {
	LOAD = '[statistics-crm] Load',
	UPDATE = '[statistics-crm] Update',
	RESET = '[statistics-crm] Reset',
	ERROR = '[statistics-crm] Error'
}

export namespace StatisticsCrmStateAction {
	export const load = createAction(StatisticsCrmActionEnum.LOAD, props<BaseStateModel<any>>());
	export const update = createAction(StatisticsCrmActionEnum.UPDATE, props<BaseStateModel<StatisticsCrm>>());
	export const reset = createAction(StatisticsCrmActionEnum.RESET);
	export const error = createAction(StatisticsCrmActionEnum.ERROR, props<BaseStateModel<any>>());
}
