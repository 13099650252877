import { NgModule } from "@angular/core";
import { StoreModule } from '@ngrx/store';
import { UserStateReducer } from "./user.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('user', UserStateReducer.reducer)
    ]
})
export class UserStateModule{}
