import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { HeaderTablePouchModel, BodyTablePouchModel, BodyTableStandardPouchModel } from '@saep-ict/pouch_agent_models';

export class AuxiliaryTablePouch extends AbstractPouchdbMethod {
	getAuxiliaryTable(id: string): Promise<(BodyTablePouchModel | BodyTableStandardPouchModel)[]> {
		return new Promise((resolve, reject) => {
			this.get(id)
				.then((doc: HeaderTablePouchModel<BodyTablePouchModel | BodyTableStandardPouchModel>) => {
					resolve(doc.values);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
}
