import { Input, OnInit, Directive } from '@angular/core';
import { GlobalColorsEnum } from '@saep-ict/angular-core';

@Directive()
export abstract class BaseIllustrationComponent implements OnInit {
	color: string;
	@Input() theme: 'primary' | 'accent' = 'accent';

	ngOnInit() {
		this.color = `var(${GlobalColorsEnum[this.theme.toUpperCase()]})`;
	}
}
