<div class="highlight-number">
	<div class="highlight-number__title">
		{{ title | translate | sentencecase }}
	</div>

	<div class="highlight-number__subtitle">
		{{ subtitle | translate | sentencecase }}
	</div>

	<div class="highlight-number__value">
		{{ value }}
	</div>
</div>
